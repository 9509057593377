.project-image-div {
  position: relative;
  width: 770px;
  height: 450px;
  margin-left: 40%;
  margin-top: 5%;
}

.project-image-div2 {
  position: relative;
  width: 450px;
  height: 450px;
  margin-left: 53%;
  margin-bottom: 1%;
}

.project-image-div:hover {
  cursor: pointer;
}

.project-image-div2:hover {
  cursor: pointer;
}

.project-image {
  border-radius: 10px;
  width: 700px;
  height: 450px;
}

.project-image2 {
  border-radius: 10px;
  width: 310px;
  height: 510px;
}

.caption {
  color: white;
  font-size: 21px;
}

@media screen and (max-width: 1300px) {
  .project-image-div {
    position: relative;
    width: 650px;
    height: 330px;
    margin-left: 40%;
    margin-top: 5%;
  }

  .project-image {
    border-radius: 10px;
    width: 580px;
    height: 330px;
  }
}

/*Ipad Pro*/
@media screen and (max-width: 1024px) {
  .project-image-div {
    position: relative;
    width: 770px;
    height: 390px;
    margin-left: 8%;
    margin-top: 5%;
  }

  .project-image-div2 {
    position: relative;
    width: 770px;
    height: 390px;
    margin-top: 5%;
    margin-left: 7%;
  }

  .project-image {
    border-radius: 10px;
    width: 700px;
    height: 390px;
  }
}

/*Ipad Air*/
@media screen and (max-width: 900px) {
  .project-image-div {
    position: relative;
    width: 650px;
    height: 390px;
    margin-left: 3.5%;
    margin-top: 5%;
  }

  .project-image-div2 {
    position: relative;
    width: 600px;
    height: 390px;
    margin-top: 5%;
    margin-left: 7.1%;
  }

  .project-image {
    border-radius: 10px;
    width: 650px;
    height: 390px;
  }
}

/*IPhone X*/
@media screen and (max-width: 428px) {
  .project-image-div {
    width: 320px;
    height: 191px;
    margin: auto;
    margin-top: 5%;
  }

  .project-image-div2 {
    position: relative;
    width: 0px;
    height: 0px;

  }

  .project-image {
    border-radius: 10px;
    width: 320px;
    height: 191px;
  }

  .caption {
    color: white;
    font-size: 13px;
  }
}

.hvr-shrink {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-shrink:hover,
.hvr-shrink:focus,
.hvr-shrink:active {
  -webkit-transform: scale(0.7);
  transform: scale(0.97);
}
