.logo-stack {
  position: relative;
  left: 3.3%;
  padding: 5px;
  border-radius: 5px;
  top: 35px;
}

.logo-stack2 {
  position: relative;
  left: 3.4%;
  padding: 5px;
  border-radius: 5px;
  top: 55px;
}

.tech-logo {
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
}

@media screen and (max-width: 1300px) {
  .logo-stack {
    top: 185px;
    left: 4%;
  }

  .logo-stack2 {
    position: relative;
    left: 4.1%;
    padding: 5px;
    border-radius: 5px;
    top: 75px;
  }
}

/*Ipad Pro*/
@media screen and (max-width: 1024px) {
  .logo-stack {
    left: 9%;
    padding: 5px;
    border-radius: 5px;
    top: 420px;
  }

  .logo-stack2 {
    position: relative;
    left: 9%;
    padding: 5px;
    border-radius: 5px;
    top: 520px;
  }
}

/*Ipad Air*/
@media screen and (max-width: 900px) {
  .logo-stack {
    left: 9%;
    padding: 5px;
    border-radius: 5px;
    top: 435px;
  }

  .logo-stack2 {
    position: relative;
    left: 9%;
    padding: 5px;
    border-radius: 5px;
    top: 580px;
  }
}

/*IPhone X*/
@media screen and (max-width: 428px) {
  .logo-stack {
    left: 3%;
    padding: 5px;
    border-radius: 5px;
    top: 445px;
  }

  .logo-stack2 {
    position: relative;
    left: 4%;
    padding: 0px;
    border-radius: 5px;
    top: 415px;
  }

  .tech-logo {
    width: 30px;
    height: 30px;
    display: flex;
  }
}
