h4 {
  margin-bottom: 10px;
}

.projects-div {
  width: 100vw;
  max-width: 100%;
}

.banksee {
  position: relative;
  margin: auto;
  text-align: center;
  height: 720px;
  width: 1300px;
  margin-bottom: 5vh;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: #d9688e;
}

.ethical-review {
  position: relative;
  margin: auto;
  text-align: center;
  height: 720px;
  width: 1300px;
  margin-bottom: 5vh;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: #00539b;
}

.spread-the-dead {
  position: relative;
  margin: auto;
  text-align: center;
  height: 720px;
  width: 1300px;
  margin-bottom: 5vh;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: rgb(38, 38, 38);
}

.linear-codes {
  position: relative;
  margin: auto;
  text-align: center;
  height: 720px;
  width: 1300px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background-color: #838996;
}

.project-summary {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: left;
  color: white;
}

.project-summary3 {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: left;
  color: white;
}

.banksee-key-features {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: left;
  color: white;
}

.ethical-key-features {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: left;
  color: white;
}

.dead-key-features {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: left;
  color: white;
}

.code-key-features {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: left;
  color: white;
}

.banksee-technologies {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: left;
  color: white;
}

.ethical-technologies {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: justify;
  color: white;
}

.dead-technologies {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: justify;
  color: white;
}

.code-technologies {
  position: relative;
  font-size: 19px;
  margin-left: 4%;
  bottom: 78%;
  width: 34%;
  text-align: justify;
  color: white;
}

.projects-heading {
  text-align: center;
  font-size: 55px;
}

.project-title {
  font-size: 40px;
  padding-top: 2vh;
  color: white;
}

.banksee a:hover {
  color: black;
}

.ethical-review a:hover {
  color: black;
}

.spread-the-dead a:hover {
  color: black;
}

.linear-codes .project-title:hover {
  color: black;
}

.linear-codes a:hover {
  color: black;
}

li {
  margin: 5px 0;
}

.slide-button-left {
  transform: scale(1.4);
  background: none;
  border: 0px;
  color: white;
  margin-left: 8.5px;
}

.slide-button-right {
  transform: scale(1.4);
  background: none;
  border: 0px;
  color: white;
}

@media screen and (max-width: 1300px) {
  .banksee {
    width: 1100px;
    height: 730px;
  }

  .ethical-review {
    width: 1100px;
    height: 730px;
  }

  .spread-the-dead {
    width: 1100px;
    height: 730px;
  }

  .linear-codes {
    width: 1100px;
    height: 730px;
  }

  .project-summary {
    font-size: 18px;
    margin-left: 5%;
    width: 30%;
    bottom: 59%;
    text-align: left;
  }

  .project-summary3 {
    font-size: 18px;
    margin-left: 5%;
    width: 30%;
    bottom: 75%;
    text-align: left;
  }

  .banksee-technologies {
    width: 33%;
    font-size: 17px;
    margin-left: 5%;
    bottom: 75%;
  }

  .banksee-key-features {
    width: 30%;
    font-size: 18px;
    margin-left: 5%;
    bottom: 75%;
    text-align: left;
  }

  .ethical-key-features {
    width: 30%;
    font-size: 18px;
    margin-left: 5%;
    bottom: 59%;
    text-align: left;
  }

  .ethical-technologies {
    width: 30%;
    font-size: 17px;
    margin-left: 5%;
    bottom: 59%;
  }

  .dead-key-features {
    width: 30%;
    font-size: 17px;
    margin-left: 5%;
    bottom: 59%;
    text-align: left;
  }

  .dead-technologies {
    width: 30%;
    font-size: 17px;
    margin-left: 5%;
    bottom: 59%;
  }

  .code-key-features {
    width: 30%;
    font-size: 17px;
    margin-left: 5%;
    bottom: 59%;
    text-align: left;
  }

  .code-technologies {
    width: 30%;
    font-size: 17px;
    margin-left: 5%;
    bottom: 59%;
  }
}

/*Ipad Pro*/
@media screen and (max-width: 1024px) {
  .projects-heading {
    font-size: 50px;
  }

  .project-title {
    font-size: 30px;
  }

  .banksee {
    height: 1100px;
    width: 900px;
  }

  .ethical-review {
    height: 1000px;
    width: 900px;
  }

  .spread-the-dead {
    height: 1000px;
    width: 900px;
  }

  .linear-codes {
    height: 1000px;
    width: 900px;
  }

  .project-summary {
    font-size: 17px;
    margin-left: 10%;
    bottom: 10px;
    width: 40%;
    text-align: left;
  }

  .project-summary3 {
    font-size: 17px;
    margin-left: 10%;
    top: 130px;
    width: 40%;
    text-align: left;
  }

  .banksee-key-features {
    font-size: 17px;
    margin-left: 55%;
    top: -50px;
    width: 34%;
  }

  .ethical-key-features {
    font-size: 17px;
    margin-left: 55%;
    top: -190px;
    width: 34%;
    text-align: left;
  }

  .dead-key-features {
    font-size: 17px;
    margin-left: 55%;
    top: -190px;
    width: 34%;
    text-align: left;
  }

  .code-key-features {
    font-size: 17px;
    margin-left: 55%;
    top: -235px;
    width: 34%;
    text-align: left;
  }

  .banksee-technologies {
    font-size: 16px;
    margin-left: 10%;
    top: -50px;
    width: 34%;
  }

  .ethical-technologies {
    font-size: 16px;
    margin-left: 10%;
    top: -210px;
    width: 34%;
  }

  .dead-technologies {
    font-size: 16px;
    margin-left: 10%;
    top: -160px;
    width: 34%;
  }

  .code-technologies {
    position: relative;
    font-size: 16px;
    margin-left: 10%;
    top: -210px;
    width: 34%;
    text-align: justify;
    color: white;
  }
}

/*Ipad Air*/
@media screen and (max-width: 900px) {
  .projects-heading {
    font-size: 50px;
  }

  .project-title {
    font-size: 30px;
  }

  .banksee {
    height: 1150px;
    width: 700px;
  }

  .ethical-review {
    height: 1000px;
    width: 700px;
  }

  .spread-the-dead {
    height: 1000px;
    width: 700px;
  }

  .linear-codes {
    height: 1000px;
    width: 700px;
  }

  .project-summary {
    font-size: 16px;
    margin-left: 10%;
    bottom: 10px;
    width: 34%;
  }

  .key-features {
    font-size: 16px;
    margin-left: 55%;
    top: -160px;
    width: 34%;
  }

  .banksee-key-features {
    font-size: 16px;
    margin-left: 55%;
    top: -70px;
    width: 34%;
  }

  .ethical-key-features {
    font-size: 16px;
    margin-left: 55%;
    top: -245px;
    width: 34%;
  }

  .dead-key-features {
    font-size: 16px;
    margin-left: 55%;
    top: -245px;
    width: 34%;
  }

  .code-key-features {
    font-size: 16px;
    margin-left: 55%;
    top: -290px;
    width: 34%;
  }

  .banksee-technologies {
    font-size: 16px;
    margin-left: 10%;
    top: -40px;
    width: 34%;
  }

  .technologies {
    font-size: 16px;
    margin-left: 10%;
    top: -130px;
    width: 34%;
  }

  .ethical-technologies {
    font-size: 16px;
    margin-left: 10%;
    top: -250px;
    width: 34%;
  }

  .dead-technologies {
    font-size: 16px;
    margin-left: 10%;
    top: -210px;
    width: 34%;
  }

  .code-technologies {
    font-size: 16px;
    margin-left: 10%;
    top: -210px;
    width: 34%;
  }
}

/*IPhone X*/
@media screen and (max-width: 428px) {
  .projects-heading {
    font-size: 40px;
  }

  .project-title {
    font-size: 18px;
  }

  .banksee {
    height: 520px;
    width: 100%;
    box-shadow: none;
  }

  .ethical-review {
    height: 750px;
    width: 100%;
    box-shadow: none;
  }

  .spread-the-dead {
    height: 750px;
    width: 100%;
    box-shadow: none;
  }

  .linear-codes {
    height: 750px;
    width: 100%;
    box-shadow: none;
  }

  .project-summary {
    font-size: 15px;
    margin-left: 5%;
    bottom: 10px;
    width: 90%;
    text-align: left;
    color: white;
  }

  .project-summary3 {
    font-size: 15px;
    margin-left: 5%;
    top: -60px;
    width: 90%;
    text-align: left;
  }

  .banksee-key-features {
    font-size: 15px;
    margin-left: 5%;
    top: -50px;
    width: 90%;
    text-align: left;
  }

  .ethical-key-features {
    font-size: 15px;
    margin-left: 5%;
    top: -15px;
    width: 90%;
    text-align: left;
  }

  .dead-key-features {
    font-size: 15px;
    margin-left: 5%;
    top: -10px;
    width: 90%;
    text-align: left;
  }

  .code-key-features {
    font-size: 15px;
    margin-left: 5%;
    top: -10px;
    width: 90%;
    text-align: left;
  }

  .banksee-technologies {
    font-size: 15px;
    margin-left: 5%;
    top: -40px;
    width: 90%;
  }

  .ethical-technologies {
    font-size: 14px;
    margin-left: 5%;
    top: -25px;
    width: 90%;
  }

  .dead-technologies {
    font-size: 14px;
    margin-left: 5%;
    top: -10px;
    width: 90%;
  }

  .code-technologies {
    font-size: 14px;
    margin-left: 5%;
    top: -10px;
    width: 90%;
  }

  .technologies {
    font-size: 14px;
    margin-left: 5%;
    top: -10px;
    width: 90%;
  }

  .slide-button-left {
    transform: scale(1.1);
    background: none;
    border: 0px;
    color: white;
    margin-left: 8.5px;
    border-color: black;
  }

  .slide-button-right {
    transform: scale(1.1);
    background: none;
    border: 0px;
    color: white;
  }
}
