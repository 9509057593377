.footer {
  position: relative;
  height: 50px;
  color: white;
  font-size: 23px;
  text-align: center;
  bottom: 29px;
}

.copyright {
  position: relative;
  display: flex;
  text-align: left;
  padding-left: 11%;
  font-size: 20px;
}

.note {
  position: relative;
  text-align: center;
  font-size: 20px;
  bottom: 25px;
  color: white;
}

/*IPhone X*/
@media screen and (max-width: 428px) {
  .footer {
    position: relative;
    height: 50px;
    color: white;
    font-size: 23px;
    text-align: center;
    bottom: 29px;
  }

  .copyright {
    position: relative;
    display: flex;
    text-align: center;
    padding-left: 35%;
    margin-top: 30px;
    font-size: 13px;
  }

  .note {
    position: relative;
    text-align: center;
    font-size: 12px;
    bottom: 25px;
    color: white;
  }
}
