.introduction-div {
  color: white;
  padding-top: 1vh;
  background: black;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}

.introduction-div a:hover {
  color: #38FEA3;
}

.name {
  font-size: 120px;
  margin-bottom: -1%;
  min-width: 40%;
}

.job-title {
  font-size: 55px;
}

.profile-description {
  position: relative;
  font-size: 30px;
  max-width: 970px;
  text-align: justify;
  margin: auto;
  top: 30px;

}

.contact-me {
  position: relative;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: center;
  top: 80px;
  font-size: 30px;
}

@keyframes floating2 {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -30px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.down-arrow {
  position: relative;
  text-align: center;
  margin-top: 180px;
  padding-bottom: 55px;

  animation: fadeInAnimation ease 3.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  animation-name: floating2;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.down-arrow-svg {
  width: 95px;
  height: 95px;
}

@media screen and (max-width: 1300px) {
  .name {
    font-size: 100px;
  }
  .profile-description {
    text-align: left;
  }
}


/*Ipad Pro*/
@media screen and (max-width: 1024px) {
  .introduction-div {
    min-width: 100vw;
    min-height: 100vh;
    margin-bottom: 0px;
  }

  .name {
    font-size: 100px;
    margin-bottom: -1%;
    min-width: 40%;
  }

  .job-title {
    font-size: 50px;
  }

  .profile-description {
    font-size: 30px;
    max-width: 700px;
    top: 25px;
  }

  .down-arrow {
    margin-top: 200px;
    padding-bottom: 10px;
  }

  .down-arrow-svg {
    width: 100px;
    height: 100px;
  }
}

/*Ipad Air*/
@media screen and (max-width: 820px) {
  .name {
    font-size: 70px;
  }

  .job-title {
    font-size: 35px;
  }

  .profile-description {
    font-size: 25px;
  }

  .down-arrow {
    margin-top: 300px;
    padding-bottom: 10px;
  }

  .down-arrow-svg {
    width: 80px;
    height: 80px;
  }
}

/*IPhone X*/
@media screen and (max-width: 428px) and (max-height: 926px) {
  .introduction-div {
    min-width: 100vw;
    min-height: 100vh;
    margin-bottom: 0px;;
  }
  .name {
    font-size: 55px;
    margin-bottom: -1%;
    min-width: 10%;
  }

  .job-title {
    font-size: 26px;
  }

  .profile-description {
    font-size: 22px;
    max-width: 90%;
    top: 5px;
    left:5px;
    text-align: left;
  }

  .down-arrow {
    padding-bottom: 10px;
    margin-top: 80px;
  }

  .down-arrow-svg {
    width: 60px;
    height: 60px;
  }
}
