.social-div {
  position: relative;
  background: white;
  width: 475px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 15px;
  top: 35px;
  justify-content: center;
  margin: auto;
}

.icon1 {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 1%;
  width: 100px;
  height: 100px;

  animation-name: floating;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -1s;
}

.icon2 {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 1%;
  width: 100px;
  height: 100px;

  animation-name: floating;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -2s;
}

.icon3 {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 1%;
  width: 100px;
  height: 100px;
  animation: fadeInAnimation ease 3.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;

  animation-name: floating;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -3s;
}

.icon4 {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 1%;
  width: 100px;
  height: 100px;

  animation-name: floating;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: -4s;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

/*Ipad Pro*/
@media screen and (max-width: 1024px) {
  .social-div {
    position: relative;
    background-color: white;
    width: 450px;
    padding-top: 5px;
    padding-bottom: 5px;
    top: 100px;
  }

  .icon1 {
    width: 90px;
    height: 90px;
  }
  .icon2 {
    width: 90px;
    height: 90px;
  }

  .icon3 {
    width: 90px;
    height: 90px;
  }

  .icon4 {
    width: 90px;
    height: 90px;
  }
}

/*Ipad Air*/
@media screen and (max-width: 820px) {
  .social-div {
    position: relative;
    background-color: white;
    width: 450px;
    padding-top: 5px;
    padding-bottom: 5px;
    top: 100px;
  }

  .icon1 {
    width: 80px;
    height: 80px;
  }
  .icon2 {
    width: 80px;
    height: 80px;
  }

  .icon3 {
    width: 80px;
    height: 80px;
  }

  .icon4 {
    width: 80px;
    height: 80px;
  }
}

/*IPhone X*/
@media screen and (max-width: 428px) {
  .social-div {
    position: relative;
    background-color: white;
    width: 320px;
    padding-top: 5px;
    padding-bottom: 5px;
    top: 10px;
  }

  .icon1 {
    width: 50px;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .icon2 {
    width: 50px;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .icon3 {
    width: 50px;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .icon4 {
    width: 50px;
    height: 50px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
