html {
  scroll-behavior: smooth;

}

body {
  font-family: "Manrope";
  position: absolute;
}

html, body {
  max-width: 100%;
  overflow-x: clip;
}

.top {
  position: relative;
  margin: auto;
  text-align: center;
  font-size: 30px;
  bottom: 15px;
  width: 200px;
  color: white;
}

.top:hover {
  font-weight: bolder;
  color: #38FEA3;
}

.footer-div {
  position:relative;
  height: 200px;
  padding-top: 2px;
  background: black;
  top: 10.5vh;
}

@-moz-document url-prefix() {
 .footer-div {
  height: 360px;
 }
}

/*Ipad Pro*/
@media screen and (max-width: 1024px) and (max-height: 1366px)  {
  .top {
    position: relative;
    margin: auto;
    text-align: center;
    justify-content: center;
    font-size: 30px;
    bottom: 15px;
    width: 200px;
    color: white;
  }
   
  .footer-div {
    position:relative;

    padding-top: 2px;
    top: 10.5vh;
  }
}

/*IPhone X*/
@media screen and (max-width: 428px) and (max-height: 926px) {
  .top {
    position: relative;
    margin: auto;
    text-align: center;
    justify-content: center;
    font-size: 22px;
    bottom: 15px;
    width: 200px;
    color: white;
  }

  .footer-div {
    position:relative;
    min-height: 140px;
    padding-top: 2px;
    top: 10.5vh;
  }
}
